<script setup lang="ts">
const { t } = useT();
const { open } = useTaoModals();
const loginGuard = useLoginGuard();

const handleGiftClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};
</script>

<template>
	<div class="gift-label" @click="handleGiftClick">
		<NuxtImg
			src="/nuxt-img/deposit-streak/gift/icon-menu.webp"
			width="171"
			height="141"
			loading="lazy"
			format="avif"
			alt="icon-menu"
		/>
		<AText class="text-coro" :modifiers="['bold', 'uppercase']">{{ t("Streak") }}</AText>
		<AText variant="tallinn" class="text-coro" :modifiers="['uppercase']">{{ t("To win") }}</AText>
	</div>
</template>

<style lang="scss" scoped>
.gift-label {
	width: 90px;
	padding: 4px;
	cursor: pointer;
	height: 60px;
	position: fixed;
	right: 0;
	bottom: 270px;
	background: var(--gaalkacyo);
	border-radius: 10px 0 0 10px;
	border: 2px solid var(--caldas);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	@include media-breakpoint-down(sm) {
		bottom: 270px;
	}

	img {
		position: absolute;
		right: 0;
		bottom: 27px;
		width: 90px;
		height: auto;
		object-fit: cover;
		animation: bounce 1s infinite;
	}
}

@keyframes bounce {
	from,
	20%,
	53%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -15px, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -8px, 0);
	}

	90% {
		transform: translate3d(0, -2px, 0);
	}
}
</style>
